<template>
	<div>
		<h4 class="mb-4">
			Postcode
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				data-test-id="button-add"
				@click="$refs['modal-post-code'].open()"
			>
				{{ hasListItem ? 'Edit' : 'Add' }}
			</CButton>
		</h4>
		<div class="mt-3 postcode-box" :class="{ 'is-invalid': !isValid }">
			<BaseNoItemBanner v-if="!hasListItem" text="There are no postcode for this additional fee" />
			<ul v-else class="postcode-list">
				<li v-for="item in list" :key="item" class="list-item typo-body-2">
					{{ item }}
				</li>
			</ul>
		</div>
		<div
			v-if="invalidFeedback"
			class="invalid-feedback"
		>
			{{ invalidFeedback }}
		</div>
		<ModalPostcode
			ref="modal-post-code"
			:list="list"
			data-test-id="modal-post-code"
			@onConfirm="handleListChange"
		/>
	</div>
</template>

<script>
import ModalPostcode from '@/components/ModalPostcode.vue';

export default {
	name: 'FormPostcode',
	components: {
		ModalPostcode,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		hasListItem() {
			return this.list && this.list.length > 0;
		},
	},
	methods: {
		handleListChange(list) {
			this.$emit('onChange', list);
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-group-text {
		padding-right: 0;
	}

	.btn-add {
		min-width: rem(80);
	}

	.postcode-box {
		&.is-invalid {
			border: 1px solid $color-alert;
			border-radius: rem(4);
		}
	}

	.postcode-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		list-style: none;

		.list-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}
	}
</style>
