<template>
	<form
		class="form-create-rate main-wrapper mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow class="mb-5">
			<CCol lg="12">
				<h4>Additional Fee</h4>
				<div class="form-group mt-3 mb-5">
					<BaseInputNumber
						id="shipping-fee"
						v-model="$v.localFee.$model"
						:decimal-scale="2"
						:is-valid="!$v.localFee.$error"
						:invalid-feedback="feeErrors"
						label="Additional fee"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
					/>
				</div>
				<hr>
			</CCol>
			<CCol lg="12" class="mt-4">
				<FormPostcode
					:list="localPostCodeList"
					:is-valid="!$v.localPostCodeList.$error"
					:invalid-feedback="$t('global.error.required')"
					class="mb-5"
					@onChange="handlePostcodeChange"
				/>
				<hr>
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:is-edit="isEdit"
			:remove-text="isEdit ? 'Remove rule' : null"
			content-class="main-wrapper"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'ShippingSetting'})"
			@onRemove="isEdit ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			title="Delete this Postcodes additional fee rule?"
			description="Deleting rule will affect upcoming orders immediately."
			@onSuccess="handleRemoveRule"
		/>
	</form>
</template>

<script>
import { required, maxValue } from 'vuelidate/lib/validators';
import FormPostcode from '@/components/FormPostcode.vue';
import { MAXIMUM_FEE } from '../enums/shippings';

export default {
	name: 'FormPostcodeAdditionalFee',
	components: {
		FormPostcode,
	},
	validations: {
		localFee: {
			required,
			maxValue: maxValue(MAXIMUM_FEE),
		},
		localPostCodeList: {
			required,
		},
	},
	props: {
		fee: {
			type: [String, Number],
			default: '',
		},
		postCodeList: {
			type: Array,
			default: () => [],
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			localFee: null,
			localPostCodeList: [],
		};
	},
	computed: {
		feeErrors() {
			if (!this.$v.localFee.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.localFee.maxValue) {
				return 'Please input the maximum number 99,999,999.99';
			}

			return null;
		},

		isEdit() {
			return this.$listeners.onRemoveRule != null;
		},
	},
	mounted() {
		this.localFee = this.fee;
		this.localPostCodeList = this.postCodeList;
	},
	methods: {
		handlePostcodeChange(list) {
			this.localPostCodeList = [...list];
		},
		handleRemoveRule() {
			this.$emit('onRemoveRule');
		},
		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}
			this.$emit('onSubmit', this.localFee, this.localPostCodeList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-group {
		::v-deep .input-group {
			max-width: rem(210);
		}
	}
</style>
