<template>
	<div>
		<div>
			<CInput
				v-model.trim="postcode"
				placeholder="Search by postcode"
				class="flex-fill"
				autocomplete="off"
				data-test-id="input-postcode"
			>
				<template #prepend-content>
					<CIcon class="cil-magnifying-glass" name="cil-magnifying-glass" />
				</template>
			</CInput>
		</div>
		<slot
			v-if="!filteredList.length"
			name="no-item"
		>
		</slot>
		<div v-show="filteredList.length" class="list">
			<div class="select-all mb-1 align-items-center">
				<CInputCheckbox
					:checked.sync="isSelectedAll"
					custom
					label="Select all"
					class="select-all-input"
					add-label-classes="font-weight-bold"
					data-test-id="select-all-input"
					@update:checked="handleCheckedSelectAll"
				/>
			</div>
			<RecycleScroller
				:items="filteredList"
				:min-item-size="35"
				key-field="postcode"
				class="scroller list-select"
			>
				<template v-slot="{ item }">
					<div class="d-flex flex-fill list-item">
						<CInputCheckbox
							:id="item.postcode"
							:checked.sync="selectedPostcodes[item.postcode]"
							custom
							class="list-checkbox overflow-hidden"
							@update:checked="handleCheckedPostcode"
						>
							<template #label>
								<label :for="item.postcode" class="custom-control-label list-label w-100 typo-body-2 font-weight-normal text-nowrap text-truncate color-black-45 mb-0 ml-2">
									<span class="list-postcode font-weight-bold color-black">{{ item.postcode }}</span>
									<span class="border-right ml-2 mr-2 pr-2 d-inline-block">
										<span class="list-province list-address">{{ item.provinces }}</span>
									</span>
									<span class="d-inline-block">
										<span class="list-district list-address">{{ item.districts }}</span>
									</span>
								</label>
							</template>
						</CInputCheckbox>
					</div>
				</template>
			</RecycleScroller>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

const mapToPostcode = (selectedPostcodes) => Object.entries(selectedPostcodes)
	.filter((select) => select[1])
	.map((select) => select[0]);

export default {
	name: 'FormGetPostcode',
	components: {
		RecycleScroller,
	},
	props: {
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			addressInfoList: [],
			selectedPostcodes: {},
			isSelectedAll: false,
			postcode: '',
		};
	},
	computed: {
		...mapGetters({
			getAddressInfoByPartialPostcode: 'addresses/getAddressInfoByPartialPostcode',
			getAllAddressInfo: 'addresses/getAllAddressInfo',
		}),
		filteredList() {
			return this.addressInfoList.filter((addressInfo) => addressInfo.postcode.startsWith(this.postcode));
		},
	},
	watch: {
		filteredList() {
			this.checkSelectionState();
		},

		selectedList() {
			this.checkSelectionState();
		},
	},
	async created() {
		await this.fetchProvinces();
		this.addressInfoList = this.getAllAddressInfo;
		this.selectedPostcodes = this.selectedList.reduce((acc, postcode) => ({ ...acc, [postcode]: true }), {});
	},
	methods: {
		...mapActions({
			fetchProvinces: 'addresses/fetchProvinces',
		}),
		resetState(originalList = []) {
			this.postcode = '';
			this.selectedPostcodes = originalList.reduce((acc, postcode) => ({ ...acc, [postcode]: true }), {});
		},
		checkSelectionState() {
			const isEverySelected = this.filteredList.length && this.filteredList.every((address) => this.selectedPostcodes[address.postcode]);
			const isSomeSelected = this.filteredList.length && this.filteredList.some((address) => this.selectedPostcodes[address.postcode]);

			if (isEverySelected) {
				this.setSelectedAllState('checked');
				return;
			}

			if (isSomeSelected) {
				this.setSelectedAllState('indeterminated');
				return;
			}

			this.setSelectedAllState('unchecked');
		},
		setSelectedAllState(state) {
			let isChecked = false;
			let isIndeterminate = false;
			switch (state) {
				case 'checked': {
					isChecked = true;
					isIndeterminate = false;
					break;
				}
				case 'unchecked': {
					isChecked = false;
					isIndeterminate = false;
					break;
				}
				case 'indeterminated': {
					isChecked = true;
					isIndeterminate = true;
					break;
				}
				default: break;
			}

			const selectAllEle = this.$el.querySelector('.select-all-input input');
			if (selectAllEle) {
				selectAllEle.checked = isChecked;
				selectAllEle.indeterminate = isIndeterminate;
			}
		},
		handleCheckedSelectAll() {
			if (this.isSelectedAll) {
				this.filteredList.forEach((addressInfo) => {
					this.selectedPostcodes[addressInfo.postcode] = true;
				});
			} else {
				this.filteredList.forEach((addressInfo) => {
					this.selectedPostcodes[addressInfo.postcode] = false;
				});
			}
			const selected = mapToPostcode(this.selectedPostcodes);
			this.$emit('onChange', selected);
		},
		handleCheckedPostcode() {
			const selected = mapToPostcode(this.selectedPostcodes);
			this.$emit('onChange', selected);
		},
	},
};
</script>

<style lang="scss" scoped>
	.scroller {
		height: 100%;
	}

	.form-get-sku-input {
		padding-right: 0;
	}

	.btn-add {
		min-width: rem(84);
	}

	.list {
		margin: 0 -#{rem(16)} -#{rem(16)};
	}

	.select-all {
		background-color: $color-gray-100;
		padding: rem(8) rem(16);
	}

	.list-select {
		min-height: rem(360);
		max-height: rem(360);
		list-style: none;
		padding: 0;
		margin: 0;

		.list-item {
			padding: rem(6) rem(16);

			label {
				margin-top: rem(3);
				cursor: pointer;
			}
		}
	}

	.list-address {
		&:not(:last-child) {
			&::after {
				content: ", ";
			}
		}
	}
</style>
